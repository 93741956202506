var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              "Thanh toán hóa đơn công lương: " +
              _vm.orderCode +
              " " +
              _vm.nameEmployee,
            visible: _vm.dialogVisible,
            width: "1200px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "1vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "margin-bottom": "15px",
                display: "inline-block",
                position: "relative",
                "z-index": "1",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  on: {
                    change: function ($event) {
                      return _vm.changeCategoryMethod()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "category", $$v)
                    },
                    expression: "dataSearch.category",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "both" } }, [
                    _vm._v("Tổng hợp"),
                  ]),
                  _c("el-radio", { attrs: { label: "out" } }, [_vm._v("Chi")]),
                  _c("el-radio", { attrs: { label: "in" } }, [_vm._v("Thu")]),
                ],
                1
              ),
            ],
            1
          ),
          _c("span", { staticStyle: { "margin-left": "745px" } }, [
            _vm._v("Số tiền tính toán:\n      "),
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(_vm._s(_vm._f("formatCurrencyNew")(_vm.moneyCheckTotal))),
            ]),
          ]),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                "empty-text": _vm.textTable,
                "highlight-current-row": "",
                data: _vm.responseData.dataList,
                "cell-style": _vm.tableRowStyle,
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": 360,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  fixed: "",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Tên khoản", prop: "name", "min-width": "180" },
              }),
              _c("el-table-column", {
                attrs: { label: "Loại", align: "center", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.category === "in"
                          ? _c("span", [_vm._v("Thu")])
                          : _vm._e(),
                        scope.row.category === "out"
                          ? _c("span", [_vm._v("Chi")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Khóa", align: "center", width: "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.locked
                          ? _c("span", [_vm._v("Đã khóa")])
                          : _c("span", [_vm._v("Chưa khóa")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Thành tiền", align: "right", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.money === 0
                          ? _c("span", [_vm._v("0")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(scope.row.money)
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Đã trả", align: "right", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.paid === 0
                          ? _c("span", [_vm._v("0")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("formatCurrency")(scope.row.paid))
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Thiếu", align: "right", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.money - scope.row.paid === 0
                          ? _c("span", [_vm._v("0")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    scope.row.money - scope.row.paid
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", align: "center", width: "85" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: {
                            disabled:
                              scope.row.locked ||
                              Math.abs(scope.row.paid) >=
                                Math.abs(scope.row.money),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.checkedRowMoneyMethod()
                            },
                          },
                          model: {
                            value: scope.row.checkMoney,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "checkMoney", $$v)
                            },
                            expression: "scope.row.checkMoney",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Chọn khoản TT",
                  fixed: "right",
                  align: "center",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: {
                            disabled:
                              scope.row.locked ||
                              Math.abs(scope.row.paid) >=
                                Math.abs(scope.row.money),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.checkedRowMethod(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.checked,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "checked", $$v)
                            },
                            expression: "scope.row.checked",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("br"),
          this.dataSearch.category === "both"
            ? _c(
                "div",
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.checkNotOrder,
                      callback: function ($$v) {
                        _vm.checkNotOrder = $$v
                      },
                      expression: "checkNotOrder",
                    },
                  }),
                  _vm._v(
                    "   Ghi chú:\n      " +
                      _vm._s(_vm.responseData.description) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-right": "15px" },
                      attrs: { span: 6 },
                    },
                    [
                      _c("div", {}, [
                        _c("div", { staticClass: "text-money" }, [
                          _vm._v(
                            "\n              Tổng tiền hóa đơn\n              "
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                float: "right",
                              },
                            },
                            [
                              _vm.responseData.moneyTotal === 0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          _vm.responseData.moneyTotal
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "text-money" }, [
                          _vm._v(
                            "\n              Tổng tiền đã trả\n              "
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                float: "right",
                              },
                            },
                            [
                              _vm.responseData.moneyTotalPaid === 0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          _vm.responseData.moneyTotalPaid
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "text-money" }, [
                          _vm._v(
                            "\n              Tiền còn lại phải trả\n              "
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                float: "right",
                              },
                            },
                            [
                              _vm.responseData.moneyTotal -
                                _vm.responseData.moneyTotalPaid ===
                              0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          _vm.responseData.moneyTotal -
                                            _vm.responseData.moneyTotalPaid
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          ),
                        ]),
                        _c("hr", {
                          staticStyle: {
                            "border-bottom": "0 solid red",
                            "margin-top": "-0.5em",
                            "margin-bottom": "0.7em",
                          },
                        }),
                        _c("p"),
                        _c("div", { staticClass: "text-money" }, [
                          _vm._v(
                            "\n              Tổng tiền nhập\n              "
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                float: "right",
                                color: "yellow",
                              },
                            },
                            [
                              _vm.dataInput.moneyInput + 0 === 0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          _vm.dataInput.moneyInput
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "text-money" }, [
                          _vm._v(
                            "\n              Tiền các khoản đã chọn\n              "
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                float: "right",
                                color: "white",
                              },
                            },
                            [
                              _vm.moneyPayment === 0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          _vm.moneyPayment
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "text-money" }, [
                          _vm.dataInput.moneyInput - _vm.moneyPayment > 0
                            ? _c("span", [
                                _vm._v("\n                Số tiền còn thừa"),
                              ])
                            : _c("span", [_vm._v(" Số tiền còn thiếu")]),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                float: "right",
                                color: "yellow",
                              },
                            },
                            [
                              _vm.dataInput.moneyInput - _vm.moneyPayment === 0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          Math.abs(
                                            _vm.dataInput.moneyInput -
                                              _vm.moneyPayment
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { float: "right" }, attrs: { span: 17 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dataInput",
                          attrs: {
                            "label-width": "145px",
                            model: _vm.dataInput,
                            "label-position": "left",
                            rules: _vm.rules,
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "45%" },
                              attrs: {
                                label: "Nhập Số tiền",
                                prop: "moneyInput",
                              },
                            },
                            [
                              _c("el-currency-input", {
                                model: {
                                  value: _vm.dataInput.moneyInput,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataInput, "moneyInput", $$v)
                                  },
                                  expression: "dataInput.moneyInput",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                display: "inline-block",
                                width: "45%",
                              },
                              attrs: {
                                label: "Người thanh toán",
                                prop: "name",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "Nhập người thanh toán" },
                                model: {
                                  value: _vm.dataInput.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataInput, "name", $$v)
                                  },
                                  expression: "dataInput.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                display: "inline-block",
                                width: "45%",
                                float: "right",
                              },
                              attrs: { label: "Ngày thanh toán", prop: "date" },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  clearable: false,
                                  "value-format": "yyyy-MM-dd",
                                  format: "dd-MM-yyyy",
                                  placeholder: "Chọn ngày",
                                },
                                model: {
                                  value: _vm.dataInput.date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataInput, "date", $$v)
                                  },
                                  expression: "dataInput.date",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Mô tả", prop: "description" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  row: 3,
                                  placeholder: "Nhập mô tả",
                                },
                                model: {
                                  value: _vm.dataInput.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataInput, "description", $$v)
                                  },
                                  expression: "dataInput.description",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.dataSearch.category === "in"
                            ? _c("el-form-item", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "13px",
                                      color: "#606266",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Lưu ý: Bạn không được nhập thừa số tiền cần thanh toán."
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticStyle: { "margin-top": "20px" } }, [
            _c("span", [_vm._v("Tên ngân hàng: ")]),
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(_vm._s(_vm.nameBank)),
            ]),
            _c("br"),
            _c("br"),
            _c("span", { staticStyle: { "margin-top": "10px" } }, [
              _vm._v("Số tài khoản: "),
            ]),
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(_vm._s(_vm.bankAccountNumber)),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Thanh toán")]),
                ]
              ),
              this.dataSearch.category === "both"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success", size: "medium" },
                      on: { click: _vm.handleShowDialogConfigSize },
                    },
                    [
                      _c("i", { staticClass: "el-icon-printer" }),
                      _c("span", [_vm._v("In hoá đơn")]),
                    ]
                  )
                : _vm._e(),
              this.dataSearch.category === "in"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success", size: "medium" },
                      on: { click: _vm.handleShowDialogConfigSize },
                    },
                    [
                      _c("i", { staticClass: "el-icon-printer" }),
                      _c("span", [_vm._v("In hoá đơn")]),
                    ]
                  )
                : _vm._e(),
              this.dataSearch.category === "out"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success", size: "medium" },
                      on: { click: _vm.handleShowDialogConfigSize },
                    },
                    [
                      _c("i", { staticClass: "el-icon-printer" }),
                      _c("span", [_vm._v("In hoá đơn")]),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Thiết lập cỡ chữ file PDF",
            visible: _vm.dialogOptionsPdfVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogOptionsPdfVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { "background-color": "transparent" } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", [_c("span", [_vm._v("Chọn cỡ chữ: ")])]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "70px" },
                      attrs: { placeholder: "Chọn cỡ chữ", size: "smail" },
                      model: {
                        value: _vm.fontSizePdf,
                        callback: function ($$v) {
                          _vm.fontSizePdf = $$v
                        },
                        expression: "fontSizePdf",
                      },
                    },
                    _vm._l(_vm.listFontSize, function (item) {
                      return _c("el-option", {
                        key: item,
                        staticStyle: { "background-color": "transparent" },
                        attrs: { value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogOptionsPdfVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loadingPdfButton, type: "primary" },
                  on: { click: _vm.handleConfirmOptionPrint },
                },
                [_vm._v("Xác nhận")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ExportPDFSalaryDiag", {
        ref: "ExportPDFSalaryDiag",
        attrs: {
          nameBank: _vm.nameBank,
          bankAccountNumber: _vm.bankAccountNumber,
          dialogVisible: _vm.showSalaryPrintDialog,
        },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeSalaryPrintDialog()
          },
        },
      }),
      _c("ExportPDFSalaryInDiag", {
        ref: "ExportPDFSalaryInDiag",
        attrs: {
          nameBank: _vm.nameBank,
          bankAccountNumber: _vm.bankAccountNumber,
          dialogVisible: _vm.showSalaryPrintInDialog,
        },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeSalaryPrintInDialog()
          },
        },
      }),
      _c("ExportPDFSalaryOutDiag", {
        ref: "ExportPDFSalaryOutDiag",
        attrs: {
          nameBank: _vm.nameBank,
          bankAccountNumber: _vm.bankAccountNumber,
          dialogVisible: _vm.showSalaryPrintOutDialog,
        },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeSalaryPrintOutDialog()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }