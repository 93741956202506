var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Chi tiết hóa đơn: " + _vm.responseData.code,
            visible: _vm.dialogVisible,
            width: "1100px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                "empty-text": _vm.textTable,
                "highlight-current-row": "",
                data: _vm.responseData.dataList,
                "cell-style": _vm.tableRowStyle,
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": _vm.$tableMaxHeight,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "50", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  fixed: "",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Tên khoản", prop: "name", "min-width": "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Loại", align: "center", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.category == "in"
                          ? _c("span", [_vm._v("Thu")])
                          : scope.row.category == "out"
                          ? _c("span", [_vm._v("Chi")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Đơn vị",
                  prop: "unit",
                  align: "center",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Khóa", align: "center", width: "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.locked
                          ? _c("span", [_vm._v("Đã khóa")])
                          : _c("span", [_vm._v("Chưa khóa")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Thành tiền", align: "right", width: "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.money == 0
                          ? _c("span", [_vm._v("0")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(scope.row.money)
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Đã trả", align: "right", width: "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.paid == 0
                          ? _c("span", [_vm._v("0")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("formatCurrency")(scope.row.paid))
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Thiếu", align: "right", width: "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.money - scope.row.paid == 0
                          ? _c("span", [_vm._v("0")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    scope.row.money - scope.row.paid
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Tác vụ",
                  fixed: "right",
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.orderSalaryHistoryDetailMethod(
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.view")))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("br"),
          _c(
            "div",
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 2,
                  placeholder: "Nhập ghi chú hóa đơn",
                },
                model: {
                  value: _vm.responseData.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.responseData, "description", $$v)
                  },
                  expression: "responseData.description",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Lưu mô tả")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SalaryPackagePaymentDetailDialog", {
        ref: "SalaryPackagePaymentDetailDialog",
        attrs: { dialogVisible: _vm.showOrderHistoryDetailDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closesOrderHistoryDetailDialog()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }