<template>
  <div style="visibility: hidden">
    <el-dialog
        title="In thanh toán tổng hợp"
        :visible.sync="dialogVisible"
        width="550px"
        :before-close="closeDialog"
        :close-on-click-modal="false"
        top="3vh"
    >
      <div ref="documentPrintEmployee" class="print_salary" style="box-sizing: border-box; display: block">
        <div v-for="(responseData, index)  in responseList" :key="index" class="print_salary no-break page-break-after"
             style="box-sizing: border-box; display: block">
          <div>
            <div style="box-sizing: border-box">
              <div style="text-align: center">
                <div style="font-weight: bold">{{ responseData.schoolName }}</div>
                <div style="margin-bottom: 4px">
                  {{ responseData.schoolAddress }}
                </div>
                <div style="font-weight: bold">
                  PHIẾU THANH TOÁN LƯƠNG THÁNG
                  {{ dateString }}
                </div>
              </div>
              <div>
                <div style="float: left">
                  <br/>
                  <div>Họ tên: {{ responseData.fullName }}</div>
                  <div>SĐT: {{ responseData.phone }}</div>
                  <div style="font-style: italic; margin-bottom: 3px">
                    {{ dateMonthYear }}
                  </div>
                </div>
                <div style="float: right; margin-right: 2px">
                  <div style="text-align: right">Mã: ..........</div>
                  <div style="text-align: right">Quyển số: ..........</div>
                  <div style="text-align: right">Số: ..........</div>
                  <div style="margin-bottom: 3px; text-align: right">Đơn vị: VNĐ</div>
                </div>
              </div>
              <br/>
              <br/>
              <div>
                <el-table
                    class="incainay"
                    ref="multipleTable"
                    :empty-text="textTable"
                    :data="responseData.dataList"
                    :cell-style="tableRowStyle"
                    :header-cell-style="tableHeaderColor"
                    :span-method="arraySpanMethod"
                    border
                >
                  <el-table-column
                      type="index"
                      label="TT"
                      width="36"
                      align="center"
                  ></el-table-column>
                  <el-table-column label="Tên khoản" width="140">
                    <template v-slot:default="scope">
                      <div
                          :style="
                    scope.row.name === 'Tổng cộng' ||
                    scope.row.name === 'Số dư trong ví' ||
                    scope.row.name === 'Số tiền đã trả' ||
                    scope.row.name === 'Số tiền còn thiếu'
                      ? 'text-align: right'
                      : ''
                  "
                      >
                        {{ scope.row.name }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="number"
                      label="SL"
                      align="center"
                      width="40"
                  ></el-table-column>
                  <el-table-column label="Đơn giá" align="right" width="76">
                    <template v-slot:default="scope">
                      <span v-if="scope.row.price === 0">0</span>
                      <span v-else>{{ scope.row.price | formatNumber }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Thành tiền" align="right" width="76">
                    <template v-slot:default="scope">
                      <span v-if="scope.row.money === 0">0</span>
                      <span v-else>{{ scope.row.money | formatNumber }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Đã trả" align="right" width="76">
                    <template v-slot:default="scope">
                      <span v-if="scope.row.paid === 0">0</span>
                      <span v-else>{{ scope.row.paid | formatNumber }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Thiếu" align="right" width="75">
                    <template v-slot:default="scope">
                      <span v-if="scope.row.remain === 0">0</span>
                      <span v-else>{{ scope.row.remain | formatNumber }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div
                  style="
              margin-top: 4px;
              font-style: italic;
              margin-bottom: 3px;
              color: red;
            "
              >
                *{{ responseData.bankInfo }}
              </div>
              <div>Ngân hàng: {{ responseData.nameBankEmployee }}</div>
              <div>Số tài khoản: {{ responseData.accountNumberBankEmployee }}
              </div>
              <div>Chủ tài khoản: {{ responseData.fullName }}
              </div>
              <el-row style="margin-bottom: 5px">
                <el-col>
                <span style="float: right; margin-right: 45px"><i>{{ currentTime }}, ngày {{ day }} tháng {{ month }} năm {{
                    year
                  }}</i></span>
                </el-col>
              </el-row>
              <el-row class="footer_field">
                <el-col :span="12"><b>Người nộp tiền</b></el-col>
                <el-col :span="12"><b>Người lập phiếu</b></el-col>
                <el-col :offset="12" :span="12" style="padding-top: 30px"><b>{{ responseData.userCreate }}</b></el-col>
              </el-row>
            </div>
<!--            <div v-if="index !== responseList.length - 1" id="page2el"></div>-->
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import EmployeeSalaryService from "@/services/EmployeeService/EmployeeSalaryService";
import html2pdf from "html2pdf.js";
import moment from "moment";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: () => false
    },
  },
  data() {
    return {
      fontSizeBase: 10,
      responseData: {
        bankInfo: "",
        nameBankEmployee: "",
        accountNumberBankEmployee: "",
        fullName: "",
      },
      textTable: this.$tableEmpty,
      responseList: [],
      loadingButton: false,
      showOrderKidsAllDialog: false,
      date: "",
      description: "",
      dateString: "",
      dateMonthYear: "",
      currentTime: '',
      day: '',
      month: '',
      year: '',
    };
  },
  watch: {
    fontSizeBase(newValue) {
      document.documentElement.style.setProperty('--custom-font-size-note-pdf', (newValue - 1.3) + "px");
    },
  },
  created() {
    // Lấy ngày hiện tại
    let now = new Date();
    // Lấy giờ-phút hiện tại
    let hours = now.getHours();
    let minutes = now.getMinutes();
    this.currentTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;

// Lấy ngày-tháng-năm hiện tại
    let dayNow = now.getDate();
    this.day = `${dayNow < 10 ? '0' + dayNow : dayNow}`
    let monthNow = now.getMonth() + 1; // Tháng bắt đầu từ 0
    this.month = `${monthNow < 3 ? '0' + monthNow : monthNow}`
    this.year = now.getFullYear();
  },
  methods: {
    moment,
    tableHeaderColor() {
      return "background-color: #b3b3b3;color: #fff;font-weight: bold;";
    },
    tableRowStyle({row}) {
      if (
          row.name === "Tổng cộng" ||
          row.name === "Số dư trong ví" ||
          row.name === "Số tiền đã trả" ||
          row.name === "Số tiền còn thiếu" ||
          row.identify === "moneyString"
      ) {
        if (row.name === "Tổng cộng") {
          return "font-weight: bold; background: #b3b3b3";
        } else if (row.identify === "moneyString") {
          return "font-style: italic";
        } else {
          return "font-weight: bold";
        }
      }
    },
    closeDialog() {
      this.$emit("dialog-close");
      setTimeout(() => {
      }, 300);
    },
    arraySpanMethod({row, columnIndex}) {
      if (row.name === "Tổng cộng") {
        if (columnIndex === 0) {
          return [0, 0];
        } else if (columnIndex === 1) {
          return [1, 4];
        } else if (columnIndex === 2) {
          return [0, 0];
        } else if (columnIndex === 3) {
          return [0, 0];
        }
      } else if (
          row.name === "Số dư trong ví" ||
          row.name === "Số tiền đã trả" ||
          row.name === "Số tiền còn thiếu"
      ) {
        if (columnIndex === 1) {
          return [1, 4];
        } else if (columnIndex === 4) {
          return [1, 3];
        } else {
          return [0, 0];
        }
      } else if (row.identify === "moneyString") {
        if (columnIndex === 1) {
          return [1, 7];
        } else {
          return [0, 0];
        }
      }
    },

    /**
     * In 1 liên
     * */
    async exportToPDFMethod(size) {
      await this.$nextTick();
      let element = this.$refs.documentPrintEmployee;
      element.style.fontSize = `${size}px`;

      let opt = {
        margin: [0.3, 0.2, 0.3, 0.2],
        filename:
            "PHIEU_THANH_TOAN_LUONG_THANG_" +
            this.moment(this.date).format("MM_YYYY") +
            ".pdf",
        image: {type: "jpeg", quality: 0.98},
        html2canvas: {scale: 3, y: 0, scrollY: 0, removeContainer: false},
        jsPDF: {unit: "in", format: "A5", orientation: "portrait"},
        pagebreak: {
          mode: ["css", "legacy"],
          before: ".page-break-before",
          after: ".page-break-after",
          avoid: ".no-break"
        },
      };
      await html2pdf()
          .set(opt)
          .from(element)
          .save()
          .then(() => {
            // Đóng dialog sau khi lưu PDF thành công
            this.$emit("dialog-close");
          })
          .catch((error) => {
            this.$message.error({
              showClose: true,
              message: "Lỗi xuất file ",
              dangerouslyUseHTMLString: true,
            });
            console.error("Lỗi khi tạo PDF:", error);  // Bắt lỗi nếu có
            this.$emit("dialog-close");
          });
    },

    // In 2 liên
    exportToPDFMethodTwo(size) {
      let element = this.$refs.documentPrintEmployee;

      // Tạo hai phiên bản của phần tử
      let clonedElement1 = element.cloneNode(true);
      let clonedElement2 = element.cloneNode(true);

      // Đặt chiều rộng và chiều cao của các phiên bản
      clonedElement1.style.width = "48%";
      clonedElement2.style.width = "48%";

      // Thiết lập cỡ chữ cho các phần tử đã clone
      clonedElement1.style.fontSize = `${size}px`; // Thay đổi kích thước font theo nhu cầu của bạn
      clonedElement2.style.fontSize = `${size}px`;

      // Tạo một div chứa cả hai phiên bản
      let container = document.createElement("div");
      container.style.display = "flex";
      container.style.gap = "4%";
      container.appendChild(clonedElement1);
      container.appendChild(clonedElement2);
      var opt = {
        margin: [0.4, 0.2, 0.3, 0.2],
        filename:
            "PHIEU_THANH_TOAN_LUONG_THANG_" +
            this.moment(this.date).format("MM_YYYY") +
            ".pdf",
        image: {type: "jpeg", quality: 0.98},
        html2canvas: {scale: 3, y: 0, scrollY: 0, removeContainer: false},
        jsPDF: {unit: "in", format: "A4", orientation: "landscape"},
        pagebreak: {
          mode: ["css", "legacy"],
          before: ".page-break-before",
          after: ".page-break-after",
          avoid: ".no-break"
        },
      };
      html2pdf()
          .set(opt)
          .from(container)
          .save()
          .then(() => {
            // Đóng dialog sau khi lưu PDF thành công
            this.$emit("dialog-close");
          })
          .catch((error) => {
            console.error("Lỗi khi tạo PDF:", error);  // Bắt lỗi nếu có
            this.$message.error({
              showClose: true,
              message: "Lỗi xuất file PDF",
              dangerouslyUseHTMLString: true,
            });
            this.$emit("dialog-close");
          });
    },
    async getDataInitialPrint(request, date) {
      this.date = date;
      this.dateString = this.moment(this.date).format("MM/YYYY");
      this.dateMonthYear =
          "Ngày " +
          this.moment().format("DD") +
          " tháng " +
          this.moment().format("MM") +
          " năm " +
          this.moment().format("YYYY");
      await EmployeeSalaryService.getOrderSalaryPrintDisplay(request)
          .then((resp) => {
            this.responseList = resp.data.data;
          })
          .catch((err) => {
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          });
    },
    async convertMoneyToString() {
      let dataList = this.responseList;
      for (let a of dataList) {
        let rowData = a.dataList.filter(
            (x) => x.name === "Số tiền còn thiếu"
        )[0];
        await import("@/services/ConvertMoneyToString").then((x) => {
          let moneyString = x.ConvertMoneyToString(rowData.money);
          let rowInput = {
            identify: "moneyString",
            name: moneyString,
          };
          a.dataList.push(rowInput);
        });
      }
    },

    async getPrintOneCopy(request, date, size) {
      await this.getDataInitialPrint(request, date)
      await this.convertMoneyToString()
      await this.exportToPDFMethod(size);
    },
    async getPrintTwoCopy(request, date, size) {
      await this.getDataInitialPrint(request, date)
      await this.convertMoneyToString()
      await this.exportToPDFMethodTwo(size);
    },
  },
};
</script>

<style lang="scss" scoped>
:root {
  --custom-font-size-pdf: 12px;
  --custom-font-size-note-pdf: 8px;
}

.size-note-pdf {
  font-size: var(--custom-font-size-note-pdf);
}

/deep/ .el-table {
  font-size: var(--custom-font-size-pdf);
}

/deep/ .el-dialog__title {
  font-size: 25px;
  color: #606266;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-table td > .cell {
  word-break: break-word;
  font-family: arial, serif;
  color: black;
  padding: 0 4px !important;
}

/deep/ .el-table th > .cell {
  word-break: break-word;
  color: black;
}

/deep/ .el-dialog__body {
  padding: 0;
}

/deep/ .el-table td {
  padding: 0 0 !important;
  border-bottom: 1px solid #989ba0;
  border-right: 1px solid #989ba0;
}

/deep/ .el-table th {
  padding: 0 0 !important;
  border-right: 1px solid #909399;
}

.el-table {
  border: 1px solid #909399;
  border-radius: 0;
}

.input-common {
  display: block;
  margin-right: 5px;
}

.print_salary {
  display: flex;
  flex-direction: column;
  width: 523px;
  margin-bottom: 0;
}

.footer_field {
  height: 18vh;
  text-align: center;
}

.no-break {
  page-break-inside: avoid;
}

.page-break-before {
  page-break-before: always;
}

.page-break-after {
  page-break-after: always;
}
</style>
