var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Thống kê công lương nhân sự",
            visible: _vm.dialogVisible,
            width: "900px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.showSpinner
            ? _c("spinner-custom")
            : _c(
                "div",
                {
                  staticStyle: {
                    "margin-bottom": "15px",
                    position: "relative",
                    "z-index": "1",
                  },
                },
                [
                  _vm._v("\n      Chọn thời gian\n      "),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "120px",
                        "margin-left": "50px",
                        "margin-right": "5px",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeMonthMethod()
                        },
                      },
                      model: {
                        value: _vm.dataSearch.startMonth,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "startMonth", $$v)
                        },
                        expression: "dataSearch.startMonth",
                      },
                    },
                    _vm._l(_vm.startMonthList, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.value, value: item.key },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px", "margin-right": "5px" },
                      on: {
                        change: function ($event) {
                          return _vm.changeMonthMethod()
                        },
                      },
                      model: {
                        value: _vm.dataSearch.endMonth,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "endMonth", $$v)
                        },
                        expression: "dataSearch.endMonth",
                      },
                    },
                    _vm._l(_vm.endMonthList, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.value, value: item.key },
                      })
                    }),
                    1
                  ),
                  _c("el-date-picker", {
                    staticClass: "input-common",
                    staticStyle: { width: "100px" },
                    attrs: {
                      clearable: false,
                      type: "year",
                      "value-format": "yyyy-MM-dd",
                      format: "yyyy",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.searchByProperties()
                      },
                    },
                    model: {
                      value: _vm.dataSearch.year,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearch, "year", $$v)
                      },
                      expression: "dataSearch.year",
                    },
                  }),
                  _c("br"),
                  _c("br"),
                  _c("div", { staticStyle: { margin: "0 auto" } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: { padding: "10px 10px" },
                                attrs: { span: 4 },
                              },
                              [_c("div", [_vm._v("Khoản chi")])]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "row-header row2-background",
                                attrs: { span: 6 },
                              },
                              [_c("div", [_vm._v("Tổng phải chi")])]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "row-header row2-background",
                                attrs: { span: 6 },
                              },
                              [_c("div", [_vm._v("Tổng đã chi")])]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "row-header row2-background",
                                attrs: { span: 6 },
                              },
                              [_c("div", [_vm._v("Còn lại phải chi")])]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "row11 align-row",
                                attrs: { span: 6 },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        _vm.responseData.moneyTotalOut
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "row11", attrs: { span: 6 } },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("formatCurrencyNew")(
                                          _vm.responseData.moneyTotalPaidOut
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "row11", attrs: { span: 6 } },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("formatCurrencyNew")(
                                          _vm.responseData.moneyTotalRemainOut
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: { padding: "10px 10px" },
                                attrs: { span: 4 },
                              },
                              [_c("div", [_vm._v("Khoản thu")])]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "row-header row1-background",
                                attrs: { span: 6 },
                              },
                              [_c("div", [_vm._v("Tổng phải thu")])]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "row-header row1-background",
                                attrs: { span: 6 },
                              },
                              [_c("div", [_vm._v("Tổng đã thu")])]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "row-header row1-background",
                                attrs: { span: 6 },
                              },
                              [_c("div", [_vm._v("Còn lại phải thu")])]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "row11 align-row",
                                attrs: { span: 6 },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("formatCurrencyNew")(
                                          _vm.responseData.moneyTotalIn
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "row11", attrs: { span: 6 } },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("formatCurrencyNew")(
                                          _vm.responseData.moneyTotalPaidIn
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "row11", attrs: { span: 6 } },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("formatCurrencyNew")(
                                          _vm.responseData.moneyTotalRemainIn
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: { padding: "10px 10px" },
                                attrs: { span: 4 },
                              },
                              [_c("div", [_vm._v("Tổng hợp")])]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "row-header row3-background",
                                attrs: { span: 6 },
                              },
                              [_c("div", [_vm._v("Tổng chi - Tổng thu")])]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "row-header row3-background",
                                attrs: { span: 6 },
                              },
                              [_c("div", [_vm._v("Đã chi - Đã thu")])]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "row-header row3-background",
                                attrs: { span: 6 },
                              },
                              [_c("div", [_vm._v("Còn lại phải chi")])]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "row11 align-row",
                                attrs: { span: 6 },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("formatCurrencyNew")(
                                          _vm.responseData.moneyTotalInOut
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "row11", attrs: { span: 6 } },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("formatCurrencyNew")(
                                          _vm.responseData.moneyTotalInOutPaid
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "row11", attrs: { span: 6 } },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("formatCurrencyNew")(
                                          _vm.responseData.moneyTotalInOutRemain
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: { padding: "10px 10px" },
                                attrs: { span: 4 },
                              },
                              [_c("div", [_vm._v("Thống kê")])]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "row-header row4-background",
                                staticStyle: { "font-weight": "normal" },
                                attrs: { span: 9 },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                Số nhân sự chưa hoàn thành hóa đơn:\n                " +
                                      _vm._s(_vm.responseData.kidsNumber) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "row-header row4-background",
                                staticStyle: { "font-weight": "normal" },
                                attrs: { span: 9 },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                Số hóa đơn chưa hoàn thành: " +
                                      _vm._s(_vm.responseData.orderNumber) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: { padding: "10px 10px" },
                                attrs: { span: 4 },
                              },
                              [_vm._v("Xuất dữ liệu")]
                            ),
                            _c(
                              "div",
                              [
                                _vm._v(
                                  "\n              1. Xuất danh sách các nhân sự có hóa đơn chưa hoàn thành:\n              "
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "text-decoration": "underline",
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.exportFileMethod("exOrder")
                                      },
                                    },
                                  },
                                  [_vm._v("Xuất file")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "align-row" },
                              [
                                _vm._v(
                                  "\n              2. Xuất danh sách các nhân sự chưa hoàn thành các khoản thu:\n              "
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "text-decoration": "underline",
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.exportFileMethod("exIn")
                                      },
                                    },
                                  },
                                  [_vm._v("Xuất file")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "align-row" },
                              [
                                _vm._v(
                                  "\n              3. Xuất danh sách các nhân sự chưa hoàn thành các khoản chi:\n              "
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "text-decoration": "underline",
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.exportFileMethod("exOut")
                                      },
                                    },
                                  },
                                  [_vm._v("Xuất file")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "align-row" },
                              [
                                _vm._v(
                                  "\n              4. Xuất danh sách thu chi tổng hợp của các nhân sự chưa hoàn\n              thành:\n              "
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "text-decoration": "underline",
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.exportFileMethod("exInOut")
                                      },
                                    },
                                  },
                                  [_vm._v("Xuất file")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "align-row" },
                              [
                                _vm._v(
                                  "\n              5. Xuất danh sách thu chi tổng hợp của các nhân sự đã hoàn\n              thành:\n              "
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "text-decoration": "underline",
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.exportFileMethod(
                                          "exInOutTrue"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Xuất file")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "align-row" },
                              [
                                _vm._v(
                                  "\n              6. Xuất danh sách thu chi tổng hợp của các nhân sự:\n              "
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "text-decoration": "underline",
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.exportFileMethod(
                                          "exInOutTotal"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Xuất file")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ExportExcelSalaryDialog", {
        ref: "ExportExcelSalaryDialog",
        attrs: { dialogVisible: _vm.showExcelDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseExcelMethod()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }