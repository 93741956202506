var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              "Thông tin hóa đơn lương các tháng: " + _vm.nameEmployeeMonth,
            visible: _vm.dialogVisible,
            width: "90%",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticStyle: { margin: "15px 0" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "115px" },
                    attrs: {
                      type: "year",
                      clearable: false,
                      placeholder: "Chọn tháng",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.searchBillYear(_vm.date)
                      },
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tabs",
                { attrs: { type: "card" } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      attrs: {
                        "empty-text": _vm.textTable,
                        "highlight-current-row": "",
                        data: _vm.responseList,
                        "cell-style": _vm.tableRowStyle,
                        "header-cell-style": _vm.tableHeaderColor,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          width: "60",
                          fixed: "",
                          prop: "month",
                          align: "center",
                          label: "Tháng",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "",
                          prop: "code",
                          label: "Mã hóa đơn",
                          width: "125",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [_vm._v(_vm._s(scope.row.code))]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Hiển thị",
                          width: "80",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleShowOne(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.view,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "view", $$v)
                                    },
                                    expression: "scope.row.view",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Khóa", width: "80", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  attrs: {
                                    disabled: !_vm.checkPermission([
                                      "salary_order_locked",
                                    ]),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleLockedOne(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.locked,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "locked", $$v)
                                    },
                                    expression: "scope.row.locked",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Trạng thái",
                          "min-width": "100",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.totalNumber == 0
                                  ? _c("span", [_vm._v("Chưa có khoản")])
                                  : scope.row.totalNumber !=
                                    scope.row.enoughNumber
                                  ? _c("span", [_vm._v("Chưa hoàn thành")])
                                  : _c("span", [_vm._v("Đã hoàn thành")]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Đủ-Thiếu-Chưa đóng",
                          width: "150",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { color: "#606266" } },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.enoughNumber) + " - "
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { color: "orange" } },
                                  [_vm._v(_vm._s(scope.row.partNumber) + " - ")]
                                ),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(_vm._s(scope.row.emptyNumber)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Tổng thu",
                          width: "120",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.moneyTotalIn
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Đã thu",
                          width: "120",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.moneyTotalPaidIn
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Thu thiếu",
                          width: "120",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.moneyTotalRemainIn
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Tổng chi",
                          width: "120",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.moneyTotalOut
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Đã chi",
                          width: "120",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.moneyTotalPaidOut
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Chi thiếu",
                          width: "120",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.moneyTotalRemainOut
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Tác vụ",
                          width: _vm.checkPermission(["salary_order_payment"])
                            ? 200
                            : 100,
                          align: "center",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.checkPermission(["salary_order_payment"])
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "success",
                                          disabled:
                                            scope.row.locked ||
                                            scope.row.totalNumber == 0,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.paidBillSalary(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("Thanh toán")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      disabled:
                                        scope.row.locked ||
                                        scope.row.totalNumber == 0,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.orderSalaryDetailMethod(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Chi tiết")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { float: "right", margin: "15px 0" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "medium" },
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-circle-close" }),
                          _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("SalaryBill", {
        ref: "SalaryBill",
        attrs: {
          nameEmployee: _vm.nameEmployee,
          dialogVisible: _vm.showSalaryBillDialog,
        },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseSalaryBill()
          },
        },
      }),
      _c("order-salary-detail-dialog", {
        ref: "OrderSalaryDetailDialog",
        attrs: { dialogVisible: _vm.showOrderSalaryDetailDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closesOrderSalaryDetailDialog()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }