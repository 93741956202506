var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chi tiết các lần thanh toán",
        visible: _vm.dialogVisible,
        width: "900px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          ref: "multipleTable",
          attrs: {
            "empty-text": _vm.textTable,
            "highlight-current-row": "",
            data: _vm.responseList,
            "header-cell-style": _vm.tableHeaderColor,
            "max-height": _vm.$tableMaxHeight,
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              fixed: "",
              label: "STT",
              width: "50",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày thanh toán", width: "130", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(
                            scope.row.orderEmployeeHistory.date
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "200",
              prop: "orderEmployeeHistory.name",
              label: "Người thanh toán",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "Tiền thanh toán", align: "right", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatCurrency")(scope.row.money))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "200",
              prop: "orderEmployeeHistory.description",
              label: "Mô tả",
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }