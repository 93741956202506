var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thông kê tổng hợp thanh toán lương",
        visible: _vm.dialogVisible,
        width: "1150px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "3vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticStyle: { margin: "0 5px" } }, [
        _c(
          "div",
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  {
                    staticClass: "row-header row1-background",
                    attrs: { span: 8 },
                  },
                  [_c("div", [_vm._v("( 1 ) Tổng thu")])]
                ),
                _c(
                  "el-col",
                  {
                    staticClass: "row-header row1-background",
                    attrs: { span: 8 },
                  },
                  [_c("div", [_vm._v("( 2 ) Tổng chi")])]
                ),
                _c(
                  "el-col",
                  {
                    staticClass: "row-header row1-background",
                    attrs: { span: 8 },
                  },
                  [_c("div", [_vm._v("( 1 ) - ( 2 )")])]
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { staticClass: "row11 align-row", attrs: { span: 8 } },
                  [
                    _vm.totalIn == 0 ? _c("div", [_vm._v("0")]) : _vm._e(),
                    _c("div", [
                      _vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totalIn))),
                    ]),
                  ]
                ),
                _c("el-col", { staticClass: "row11", attrs: { span: 8 } }, [
                  _vm.totalOut == 0 ? _c("div", [_vm._v("0")]) : _vm._e(),
                  _c("div", [
                    _vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totalOut))),
                  ]),
                ]),
                _c("el-col", { staticClass: "row11", attrs: { span: 8 } }, [
                  _vm.totalIn - _vm.totalOut == 0
                    ? _c("div", [_vm._v("0")])
                    : _vm._e(),
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatCurrency")(_vm.totalIn - _vm.totalOut)
                      )
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("br"),
        _c(
          "div",
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  {
                    staticClass: "row-header row2-background",
                    attrs: { span: 8 },
                  },
                  [_c("div", [_vm._v("( 3 ) Đã thu")])]
                ),
                _c(
                  "el-col",
                  {
                    staticClass: "row-header row2-background",
                    attrs: { span: 8 },
                  },
                  [_c("div", [_vm._v("( 4 ) Đã chi")])]
                ),
                _c(
                  "el-col",
                  {
                    staticClass: "row-header row2-background",
                    attrs: { span: 8 },
                  },
                  [_c("div", [_vm._v("( 3 ) - ( 4 )")])]
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { staticClass: "row11 align-row", attrs: { span: 8 } },
                  [
                    _vm.totalPaidIn == 0 ? _c("div", [_vm._v("0")]) : _vm._e(),
                    _c("div", [
                      _vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totalPaidIn))),
                    ]),
                  ]
                ),
                _c("el-col", { staticClass: "row11", attrs: { span: 8 } }, [
                  _vm.totalPaidOut == 0 ? _c("div", [_vm._v("0")]) : _vm._e(),
                  _c("div", [
                    _vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totalPaidOut))),
                  ]),
                ]),
                _c("el-col", { staticClass: "row11", attrs: { span: 8 } }, [
                  _vm.totalPaidIn - _vm.totalPaidOut == 0
                    ? _c("div", [_vm._v("0")])
                    : _vm._e(),
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatCurrency")(
                          _vm.totalPaidIn - _vm.totalPaidOut
                        )
                      )
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("br"),
        _c(
          "div",
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  {
                    staticClass: "row-header row3-background",
                    attrs: { span: 8 },
                  },
                  [_c("div", [_vm._v("( 5 ) Thu thiếu")])]
                ),
                _c(
                  "el-col",
                  {
                    staticClass: "row-header row3-background",
                    attrs: { span: 8 },
                  },
                  [_c("div", [_vm._v("( 6 ) Chi thiếu")])]
                ),
                _c(
                  "el-col",
                  {
                    staticClass: "row-header row3-background",
                    attrs: { span: 8 },
                  },
                  [_c("div", [_vm._v("( 5 ) - ( 6 )")])]
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { staticClass: "row11 align-row", attrs: { span: 8 } },
                  [
                    _vm.totalIn - _vm.totalPaidIn == 0
                      ? _c("div", [_vm._v("0")])
                      : _vm._e(),
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatCurrency")(
                            _vm.totalIn - _vm.totalPaidIn
                          )
                        )
                      ),
                    ]),
                  ]
                ),
                _c("el-col", { staticClass: "row11", attrs: { span: 8 } }, [
                  _vm.totalOut - _vm.totalPaidOut == 0
                    ? _c("div", [_vm._v("0")])
                    : _vm._e(),
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatCurrency")(
                          _vm.totalOut - _vm.totalPaidOut
                        )
                      )
                    ),
                  ]),
                ]),
                _c("el-col", { staticClass: "row11", attrs: { span: 8 } }, [
                  _vm.totalIn -
                    _vm.totalPaidIn -
                    (_vm.totalOut - _vm.totalPaidOut) ==
                  0
                    ? _c("div", [_vm._v("\n            0\n          ")])
                    : _vm._e(),
                  _c("div", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm._f("formatCurrency")(
                            _vm.totalIn -
                              _vm.totalPaidIn -
                              (_vm.totalOut - _vm.totalPaidOut)
                          )
                        ) +
                        "\n          "
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }